import { AppContainer } from './components/AppContainer/AppContainer'
import storeHeader from './store/storeHeader'
import { Footer } from './components/Footer/Footer'
import { createRoot, hydrateRoot } from 'react-dom/client'

const AppJSX = () => <AppContainer
  store={storeHeader}
>
  <Footer />
</AppContainer>

export default AppJSX

if (typeof window.location !== 'undefined') {
  if (document.getElementById('footer-root').innerHTML.length) {
    hydrateRoot(
      document.getElementById('footer-root'),
      <AppJSX/>
    )
  } else {
    createRoot(document.getElementById('footer-root')).render(<AppJSX/>)
  }
}
